<template>
  <div v-if="items">
    <v-btn @click="$router.push('/manager/site/pagelist/new')"
      ><v-icon>mdi-plus</v-icon>
      {{ $vuetify.lang.t("$vuetify.site.create_page") }}</v-btn
    ><br /><br />
    <template v-for="(item, index) in items">
      <v-row :key="index" v-if="item.path !=='/authentication'">
        <v-col>{{ item.name ? item.name[$i18n.locale] : "---" }} </v-col>
        <v-col>
          {{ item.path ? item.path : "---" }}
        </v-col>
        <v-col>
          <v-btn @click="editPage(index)"><v-icon>mdi-pencil</v-icon></v-btn
          >&nbsp;
          <v-btn
            @click="popupDelete(index)"
            v-if="item.path !=='/' && item.path !=='/contact_us' && item.path !=='/members' && item.path !=='/faq' && item.path !=='/authentication'"
            ><v-icon>mdi-delete</v-icon></v-btn
          >
        </v-col>
      </v-row>
    </template>

    <ConfirmRemoval :confirmRemoval="confirmRemoval" :item="itemToRemove" />
  </div>
</template>

<script>
import Api from "@/services/api";
import ConfirmRemoval from "@/components/manager/popup/site/ConfirmRemoval.vue";
export default {
  components: { ConfirmRemoval },
  data() {
    return {
      items: false,
      data: this.getData(),
      reserved: ['/','/news','contact_us','/members'],
      itemToRemove: false,
      confirmRemoval: false,
    };
  },
  watch: {
    $route() {
      this.getData();
    },
  },
  methods: {
    getData() {
      Api.page("all", this.page).then(({ data }) => {
        this.items = data;
        for (const page in this.items) {
          if (this.items[page]["path"]) {
            this.reserved.push(this.items[page]["path"].toLowerCase());
          }
          
          localStorage.reserved = JSON.stringify(this.reserved);
        }
      });
    },
    editPage(index) {
      this.$router.push("/manager/site/pagelist/" + index);
    },
    removeItem(index) {
      Api.page("delete", index).then(() => {
        this.getData();
        this.itemToRemove = false;
        this.confirmRemoval = false;
      });
    },
    popupDelete(item) {
      this.itemToRemove = item;
      this.confirmRemoval = true;
    },
  },
};
</script>
<style scoped>
.row:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>